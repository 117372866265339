// import Menlo from '../src/styles/fonts/Menlo-Regular.ttf'

@font-face {
  font-family: 'Menlo';
  font-weight: 400;
  font-style: normal;
  // font-display: swap;
  src: url('../src/styles/fonts/Menlo-Regular.ttf');
}

body {
  background-color: #282c34;
  font-family: 'Menlo';
  color: white;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
